import React from 'react'
import { Wrapper } from '../components'

const NotFound = () => (
  <>
   <Wrapper>
      <h1>Page Not Found</h1>
      <p>GET OUT OF HERE!</p>
    </Wrapper>
  </>
)

export default NotFound
